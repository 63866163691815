.pagination-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  flex-wrap: wrap;
}

.page-size-changer {
  align-self: flex-end;
}

.ant-pagination.mini {
  align-self: center;
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;