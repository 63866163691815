@import 'assets/less/palette.less';

.WarningMessage {
  margin: 2em auto;
  text-align: center;
  color: @blue-1;

  .Title {
    color: @blue-1;
  }
}
@primary-color: #355689;@process-color: #355689;@info-color: #355689;