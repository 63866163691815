@import '~assets/less/palette.less';
@import '~constants.less';

.ContentContainer {
  width: 100%;
  display: flex;

  .Tabs {
    width: 70%;
    display: block;

    margin: 0 1em 0 0;

    .AntTabs {
      .ant-tabs-bar {
        border-bottom: @border;
        padding-bottom: 1px;
      }

      .ant-tabs-nav {
        width: 100%;
        background-color: #f2f2f2;

        .ant-tabs-nav-list {
          width: 100%;
          display: flex;
          text-align: center;

          .ant-tabs-tab {
            flex: 1;
            text-align: center;
            margin: 0;
            padding-left: 17px;
            padding-right: 17px;
            justify-content: center;
          }
        }
      }

      span {
        font-size: 16px;
      }
    }
  }

  .SuiviContainer {
    width: 30%;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;