.page-size-changer {
  align-self: flex-end;
}

.pagination-without-total {
  display: flex;
  align-items: center;
  margin: 1em 0 2em 0;

  .page-changer-container {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .page-number {
    margin: 5px;
  }
}

@primary-color: #355689;@process-color: #355689;@info-color: #355689;